import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Space, Spin } from "antd";
import debounce from "lodash.debounce";
import { LoadingOutlined } from "@ant-design/icons";
import CustomRow from "components/UiComponents/CustomRow";
import CustomResult from "components/UiComponents/CustomResult";
import CustomSelect from "components/UiComponents/CustomSelect";
import CustomColumn from "components/UiComponents/CustomColumn";
import CustomButton from "components/UiComponents/CustomButton";
import CustomHeading from "components/UiComponents/CustomHeading";
import CustomSearchBar from "components/UiComponents/CustomSearchBar";
import CustomTable from "../CustomTable";
import { useFetchTableListing } from "hooks/useTableListingApi";
import useResponsiveView from "hooks/useResponsiveView";

import {
  BILLING_FREQUENCY,
  CONTRACT_DURATION,
  CONTRACT_STATUS,
  DELIVERABLE,
  PAGE_SIZE,
  SERVICE_STATUS,
  START_CRITERIA,
  USER_ROLE,
} from "utils/Constants/Constant";
import "./CustomGridView.less";

const CustomGridView = (props) => {
  const {
    listingApiRoute,
    searchCriteriaColumns,
    filters,
    searchBar,
    searchFilters,
    filterKeys,
    loadingStates,
    columns,
    titleButton,
    btnText,
    btnAction,
    btnIcon,
    btnLoading,
    btnDisabled,
    secondBtnText,
    secondBtnAction,
    secondBtnIcon,
    pageTitle,
    onDataListingChange,
    additionalParams,
    markAsDisabled,
    markAsOptions,
    markAsValue,
    handleMarkAsChange,
    onCheckboxChange,
    rowSelection,
    activeTabKey,
    deletedItems = [],
  } = props;

  const { t } = useTranslation();

  const location = useLocation();

  const uniqueKey = location.pathname;

  const { isMobile, isIpad, isLaptop, isDesktop, isLaptopBig } =
    useResponsiveView();

  // const [searchParams, setSearchParams] = useState({
  //   searchCriteria: {
  //     columns: searchCriteriaColumns,
  //   },
  //   ...additionalParams,
  //   direction: "desc",
  //   page: 1,
  //   limit: PAGE_SIZE,
  // });

  // GETTING DATA STORED IN SESSION STORAGE
  const savedState = JSON.parse(
    sessionStorage.getItem(
      `RouteData_${uniqueKey}_${activeTabKey ? activeTabKey : 1}`
    )
  );
  const savedScrollPosition = sessionStorage.getItem(
    `ScrollPosition_${uniqueKey}_${activeTabKey || 1}`
  );
  const screenData = JSON.parse(
    sessionStorage.getItem(
      `ScreenData_${uniqueKey}_${activeTabKey ? activeTabKey : 1}`
    )
  );

  // Load initial searchParams from sessionStorage using the pathname
  const [searchParams, setSearchParams] = useState(() => {
    return savedState
      ? {
          ...savedState,
          limit: savedState.limit,
        }
      : {
          searchCriteria: {
            columns: searchCriteriaColumns,
          },
          ...additionalParams,
          direction: "desc",
          page: 1,
          limit: PAGE_SIZE,
        };
  });

  const [filteredData, setFilteredData] = useState(screenData || []);

  // CHECKING IF THE SESSIONSTORAGE DATA IS UPDATED
  useEffect(() => {
    const interval = setInterval(() => {
      const latestScreenData = JSON.parse(
        sessionStorage.getItem(
          `ScreenData_${uniqueKey}_${activeTabKey ? activeTabKey : 1}`
        )
      );

      // Comparing with the current `filteredData`
      if (JSON.stringify(latestScreenData) !== JSON.stringify(filteredData)) {
        console.log("Screen data has changed in sessionStorage");
        // Setting the updated data
        setFilteredData(latestScreenData || []);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [uniqueKey, activeTabKey, filteredData]);

  const {
    data: dataListing,
    isLoading: fetchingTableListing,
    isFetching,
    isPlaceholderData,
    error,
  } = useFetchTableListing(searchParams, listingApiRoute);

  const params = new URLSearchParams(location.search);
  const tabValue = params.get("tab");

  // useEffect(() => {
  //   if (dataListing) {
  //     let newData = dataListing?.data?.data;

  //     if (additionalParams?.excludeStatus !== undefined) {
  //       newData = newData.filter(
  //         (item) => item.status !== additionalParams.excludeStatus
  //       );
  //     }

  //     // Merge logic to prevent duplicates and filter out deleted items
  //     if (tabValue === String(activeTabKey)) {
  //       setFilteredData((prevData) => {
  //         // Create a Set of existing IDs from prevData
  //         const existingIds = new Set(prevData.map((item) => item.id));

  //         // Filter out newData items that have an ID already in the existing set
  //         const uniqueNewData = newData.filter(
  //           (item) =>
  //             !existingIds.has(item.id) && !deletedItems.includes(item.id)
  //         );

  //         // Return the updated filtered data with unique new items appended
  //         return [...prevData, ...uniqueNewData];
  //       });
  //     }

  //     setPagination((prev) => ({
  //       ...prev,
  //       total: dataListing?.data?.total,
  //       currentPage: searchParams.page,
  //     }));

  //     onDataListingChange?.({
  //       ...dataListing,
  //       data: { ...dataListing.data, data: [...newData, ...filteredData] },
  //     });
  //   }
  // }, [
  //   dataListing,
  //   searchParams.page,
  //   additionalParams?.excludeStatus,
  //   onDataListingChange,
  // ]);

  useEffect(() => {
    if (dataListing) {
      let newData = dataListing?.data?.data;

      if (additionalParams?.excludeStatus !== undefined) {
        newData = newData.filter(
          (item) => item.status !== additionalParams.excludeStatus
        );
      }

      // Cases where activeTabKey is undefined (no tabs)
      if (!activeTabKey || tabValue === String(activeTabKey)) {
        setFilteredData((prevData) => {
          // Create a Set of existing IDs from prevData
          const existingIds = new Set(prevData.map((item) => item.id));

          // Filtering out newData items that have an ID already in the existing set
          const uniqueNewData = newData.filter(
            (item) =>
              !existingIds.has(item.id) && !deletedItems.includes(item.id)
          );

          // Updated filtered data with unique new items appended
          return [...prevData, ...uniqueNewData];
        });

        setPagination((prev) => ({
          ...prev,
          total: dataListing?.data?.total,
          currentPage: searchParams.page,
        }));

        onDataListingChange?.({
          ...dataListing,
          data: { ...dataListing.data, data: [...newData, ...filteredData] },
        });
      }
    }
  }, [
    dataListing,
    searchParams.page,
    additionalParams?.excludeStatus,
    onDataListingChange,
    activeTabKey,
    tabValue,
  ]);

  useEffect(() => {
    if (dataListing?.data?.data?.length === 0) {
      setFilteredData([]);

      sessionStorage.setItem(
        `ScreenData_${uniqueKey}_${activeTabKey || 1}`,
        JSON.stringify([])
      );

      setPagination((prev) => ({
        ...prev,
        total: dataListing?.data?.total,
        currentPage: 1,
      }));
    }
  }, [
    dataListing,
    searchParams.page,
    additionalParams?.excludeStatus,
    onDataListingChange,
    activeTabKey,
    tabValue,
  ]);

  // STORING ROUTE DATA IN SESSION STORAGE
  useEffect(() => {
    if (filteredData && filteredData.length > 0) {
      sessionStorage.setItem(
        `ScreenData_${uniqueKey}_${activeTabKey || 1}`,
        JSON.stringify(filteredData)
      );
    }
  }, [filteredData, uniqueKey, activeTabKey]);

  useEffect(() => {
    setFilteredData((prevData) => {
      const updatedData = prevData.filter(
        (item) => !deletedItems.includes(item.id)
      );

      return JSON.stringify(prevData) !== JSON.stringify(updatedData)
        ? updatedData
        : prevData;
    });
  }, [deletedItems]);

  // const [pagination, setPagination] = useState({
  //   currentPage: 1,
  //   pageSize: PAGE_SIZE,
  //   total: 0,
  //   showSizeChanger: false,
  //   showQuickJumper: false,
  // });

  // Automatically apply saved sorting and filters to columns
  const enrichedColumns = columns.map((col) => {
    const sortOrder =
      searchParams.column === col.key
        ? searchParams.direction === "asc"
          ? "ascend"
          : "descend"
        : null;

    const filteredValue = (() => {
      if (col.key === "status" && searchParams?.status) {
        return [searchParams.status];
      }
      if (col.key === "category" && searchParams?.category) {
        return [searchParams.category];
      }
      return null;
    })();

    return {
      ...col,
      sortOrder,
      filteredValue,
    };
  });

  const [pagination, setPagination] = useState({
    currentPage: searchParams.page || 1,
    pageSize: searchParams.limit || PAGE_SIZE,
    total: 0,
    showSizeChanger: false,
    showQuickJumper: false,
  });

  const FILTER_OPTIONS = {
    role: USER_ROLE,
    status: SERVICE_STATUS,
    ContractStatus: CONTRACT_STATUS,
    duration: CONTRACT_DURATION,
    deliverable: DELIVERABLE,
    startCriteria: START_CRITERIA,
    billingFrequency: BILLING_FREQUENCY,
  };

  const handleSearch = (searchTerm) => {
    setSearchParams((prevParams) => ({
      ...prevParams,
      search: searchTerm,
      page: 1, // Reset to page 1
    }));

    // Reset filtered data before fetching new data
    setFilteredData([]);
  };

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 600), []);

  // Saving searchParams and pagination state to sessionStorage whenever they change
  useEffect(() => {
    sessionStorage.setItem(
      `RouteData_${uniqueKey}_${activeTabKey ? activeTabKey : 1}`,
      JSON.stringify(searchParams)
    );
  }, [searchParams, uniqueKey]);

  useEffect(() => {
    sessionStorage.setItem(
      `RoutePagination_${uniqueKey}_${activeTabKey ? activeTabKey : 1}`,
      JSON.stringify(pagination)
    );
  }, [pagination, uniqueKey]);

  const handleTableChange = (newPagination, filters, sorter) => {
    // Sorting direction: convert 'ascend' to 'asc' and 'descend' to 'dsc'
    const direction =
      sorter?.order === "ascend"
        ? "asc"
        : sorter?.order === "descend"
        ? "desc"
        : undefined;

    // Getting the column key
    const column = sorter?.columnKey || undefined;

    // Joining filter status values if they exist
    const status = filters?.status?.join(",");

    const category = filters?.category ? filters.category.join(",") : undefined;

    // Reseting filteredData
    setFilteredData([]);

    setSearchParams((prev) => {
      let updatedParams = {
        ...prev,
        page: newPagination.current || 1,
        limit: newPagination.pageSize || PAGE_SIZE,
        status,
        category,
      };

      // Only add column and direction if sorting is applied
      if (direction && column) {
        updatedParams = {
          ...updatedParams,
          direction,
          column,
        };
      } else {
        // Remove column and direction if sorting is canceled
        delete updatedParams.column;
      }

      // Only add category if the filter exists
      if (category) {
        updatedParams = {
          ...updatedParams,
          category,
        };
      } else {
        // Remove category if no filter is applied
        delete updatedParams.category;
      }

      // Ensuring that when filters change, the page is reset to 1
      if (filters?.status || filters?.category) {
        updatedParams.page = 1;
        updatedParams.limit = pagination?.pageSize;
        setFilteredData([]);
      }

      return updatedParams;
    });
  };

  const handleFilterChange = (key, value) => {
    setSearchParams((prevParams) => ({
      ...prevParams,
      filterCriteria: {
        ...prevParams.filterCriteria,
        [key]: value,
      },
      page: 1,
    }));
  };

  useEffect(() => {
    setTimeout(() => {
      const tableBody = document.querySelector(".ant-table-body");

      if (savedScrollPosition && tableBody) {
        tableBody.scrollTo({
          top: parseInt(savedScrollPosition, 10),
          behavior: "smooth", // Enables smooth scrolling
        });
      }
    }, 500);
  }, [uniqueKey, activeTabKey]);

  const loadMoreData = () => {
    if (savedState.page > 1) {
    }
    if (pagination.currentPage * pagination.pageSize < pagination.total) {
      // setPageLoading(true);
      setSearchParams((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  const handleTableScroll = debounce((e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target;

    // Saving the scroll position to sessionStorage
    sessionStorage.setItem(
      `ScrollPosition_${uniqueKey}_${activeTabKey || 1}`,
      scrollTop
    );

    if (scrollHeight - scrollTop <= clientHeight + 1) {
      loadMoreData();
    }
  }, 600);

  const contentView = (
    <CustomTable
      data={filteredData}
      columns={enrichedColumns}
      onChange={handleTableChange}
      pagination={false}
      rowSelection={rowSelection}
      scroll={{
        x: "max-content",
        y: isDesktop ? 600 : isLaptopBig ? 400 : isLaptop ? 470 : 470,
      }}
      onScroll={handleTableScroll}
      footer={() => (
        <div style={{ textAlign: "center" }}>
          <Spin
            spinning={
              fetchingTableListing || isPlaceholderData || loadingStates
            }
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 18,
                  color: "#C11B2F",
                }}
                spin
              />
            }
          />
        </div>
      )}
    />
  );

  if (error)
    return (
      <CustomResult
        status="warning"
        title={t("somethingWentWrong")}
        subTitle={`${t("anErrorOccurred")}: ${error.message}`}
      />
    );

  return (
    <>
      {/* FILTERS & SEARCH FUNCTIONALITIES */}
      <div className="topHeader top-row">
        <CustomRow>
          <CustomColumn
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            className="search-filter"
          >
            {pageTitle && (
              <CustomHeading className="title">{pageTitle}</CustomHeading>
            )}
          </CustomColumn>

          <CustomColumn
            className="topheader-btn"
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
          >
            <div className="right-header">
              <div className="aside">
                {(searchFilters ||
                  searchBar ||
                  filters ||
                  titleButton ||
                  secondBtnText) && (
                  <div className="searchFilterWrapper">
                    {filterKeys && <h4>{t("filter")}</h4>}

                    {filterKeys &&
                      filterKeys.map(({ key, placeholder }) => {
                        const filterOptions = FILTER_OPTIONS[key];
                        if (!filterOptions) {
                          return null;
                        }

                        return (
                          <CustomSelect
                            key={key}
                            size="large"
                            placeholder={
                              placeholder ? placeholder : t("select")
                            }
                            popupMatchSelectWidth={true}
                            onChange={(value) => handleFilterChange(key, value)}
                            options={filterOptions.map((option) => ({
                              label: option.label,
                              value: option.value,
                            }))}
                          />
                        );
                      })}

                    {markAsOptions && (
                      <CustomSelect
                        size="large"
                        placeholder={t("markAs")}
                        popupMatchSelectWidth={true}
                        disabled={markAsDisabled}
                        value={markAsValue}
                        onChange={(e) => handleMarkAsChange(e)}
                        options={markAsOptions.map((option) => ({
                          label: option.label,
                          value: option.value,
                        }))}
                      />
                    )}

                    {searchBar && (
                      <CustomSearchBar
                        onSearch={debouncedHandleSearch}
                        defaultValue={searchParams.search || ""}
                      />
                    )}

                    {secondBtnText && (
                      <CustomButton
                        htmlType="default"
                        icon={secondBtnIcon}
                        onClick={secondBtnAction}
                      >
                        {secondBtnText}
                      </CustomButton>
                    )}

                    {titleButton && (
                      <CustomButton
                        htmlType="default"
                        className="deafult-btn"
                        icon={btnIcon}
                        onClick={btnAction}
                        disabled={btnDisabled}
                        loading={btnLoading}
                      >
                        {btnText}
                      </CustomButton>
                    )}
                  </div>
                )}
              </div>
            </div>
          </CustomColumn>
        </CustomRow>
      </div>

      {/* ONLY TABLE COMPONENT */}
      <div className="custom-table-grid-view-container">{contentView}</div>
    </>
  );
};

export default CustomGridView;
