import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import CustomRow from "components/UiComponents/CustomRow";
import CustomForm from "components/UiComponents/CustomForm";
import CustomInput from "components/UiComponents/CustomInput";
import CustomButton from "components/UiComponents/CustomButton";
import CustomColumn from "components/UiComponents/CustomColumn";
import CustomDivider from "components/UiComponents/CustomDivider";
import CustomFormItem from "components/UiComponents/CustomFormItem";
import { authenticatedRoutes } from "utils/Constants/routes.constant";
import notificationService from "utils/Notification/Notification.service";
import { DISCOUNT_STATUS_DROPDOWN, USER_TYPE } from "utils/Constants/Constant";
import { PageTitleContext } from "PageTitleContext";
import CustomInputNumber from "components/UiComponents/CustomInputNumber";
import CustomSelect from "components/UiComponents/CustomSelect";
import { useAddDiscount, useUpdateDiscount } from "hooks/useDiscountApi";

const AddEditDiscounts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const discountData = location?.state;
  const [form] = Form.useForm();
  const { setPageTitle } = useContext(PageTitleContext);

  useEffect(() => {
    setPageTitle(!discountData ? t("addDiscount") : t("editDiscount"));
  }, [setPageTitle]);

  useEffect(() => {
    if (discountData) {
      form.setFieldsValue({
        name: discountData?.name,
        percentage: discountData?.percentage,
        status: discountData?.status,
      });
    }
  }, [discountData]);

  const { mutate: addDiscount, isPending: addingDiscount } = useAddDiscount();

  const { mutate: updateDiscount, isPending: updatingDiscount } =
    useUpdateDiscount();

  const onFinish = (formData) => {
    if (!discountData) {
      addDiscount(formData, {
        onSuccess: async (response) => {
          navigate(`/${authenticatedRoutes.DISCOUNTS}`);
        },
        onError: (error) => {
          notificationService.error(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : t("errorInvitingCustomer")
          );
        },
      });
    } else {
      updateDiscount(
        { id: discountData?.id, ...formData },
        {
          onSuccess: () => {
            const screenDataKey = "ScreenData_/discounts_1";
            const sessionData = JSON.parse(
              sessionStorage.getItem(screenDataKey)
            );

            const updatedDiscount = { id: discountData?.id, ...formData };

            // Updating the product in the array
            const updatedDiscounts = sessionData.map((discount) =>
              discount.id === discountData?.id ? updatedDiscount : discount
            );

            // Saving updated list back to session storage
            sessionStorage.setItem(
              "ScreenData_/discounts_1",
              JSON.stringify(updatedDiscounts)
            );

            notificationService.success(t("discountUpdated"));
            navigate(`/${authenticatedRoutes.DISCOUNTS}`);
          },
          onError: (error) => {
            notificationService.error(
              error?.response?.data?.message
                ? error?.response?.data?.message
                : t("errorUpdatingDiscount")
            );
          },
        }
      );
    }
  };

  return (
    <div className="wrapper addEditDiscount edit">
      <CustomRow gutter={[16, 16]}>
        <CustomColumn xs={24} sm={24} md={24} lg={24} xl={24}></CustomColumn>
        <CustomColumn xs={24} sm={24} md={24} lg={24} xl={24}>
          <CustomDivider />
        </CustomColumn>
      </CustomRow>

      <div className="cont">
        <CustomForm
          form={form}
          name="addEditDiscountForm"
          onFinish={onFinish}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <CustomRow gutter={[16, 16]}>
            <CustomColumn xs={24} sm={24} md={8} lg={8} xl={8}>
              <CustomFormItem
                name="name"
                label={t("name")}
                colon={false}
                rules={[
                  {
                    required: true,
                    message: t("nameRequired"),
                  },
                ]}
              >
                <CustomInput />
              </CustomFormItem>
            </CustomColumn>

            <CustomColumn xs={24} sm={24} md={8} lg={8} xl={8}>
              <CustomFormItem
                name="percentage"
                label={`${t("discount")} (%)`}
                colon={false}
                rules={[
                  {
                    required: true,
                    message: t("percentageIsRequired"),
                  },
                  {
                    type: "number",
                    min: 1,
                    max: 100,
                    message: t("discountMustBeBetween1to100"),
                  },
                ]}
              >
                <CustomInputNumber controls={false} />
              </CustomFormItem>
            </CustomColumn>

            <CustomColumn xs={24} sm={24} md={8} lg={8} xl={8}>
              <CustomFormItem
                name="status"
                label={t("status")}
                colon={false}
                rules={[
                  {
                    required: true,
                    message: t("statusIsRequired"),
                  },
                ]}
              >
                <CustomSelect
                  size="large"
                  popupMatchSelectWidth={true}
                  options={DISCOUNT_STATUS_DROPDOWN.map((option) => ({
                    label: option.label,
                    value: option.value,
                  }))}
                />
              </CustomFormItem>
            </CustomColumn>

            <CustomColumn xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="updateBtn">
                <CustomButton
                  htmlType="submit"
                  className="deafult-btn"
                  loading={addingDiscount || updatingDiscount}
                >
                  {t("submit")}
                </CustomButton>
              </div>
            </CustomColumn>
          </CustomRow>
        </CustomForm>
      </div>
    </div>
  );
};

export default AddEditDiscounts;
