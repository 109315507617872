import {
  useQuery,
  useMutation,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/react-query";
import { SERVICE_URL } from "utils/Constants/ServiceUrl.constants";
import { fetchAPI } from "services/api.Service";
import { QUERY_KEYS } from "utils/Constants/QueryKeys.constants";

// FETCH USER
export const useFetchUser = (userParams) => {
  return useQuery({
    queryKey: [QUERY_KEYS.USER, userParams],
    queryFn: async ({ signal }) =>
      fetchAPI({
        method: "GET",
        url: SERVICE_URL.USER.USER_API,
        queryParams: userParams,
        data: {},
        signal,
      }),
    placeholderData: keepPreviousData,
  });
};

// FETCH USER BY ID
export const useFetchUserById = (id) => {
  return useQuery({
    queryKey: [QUERY_KEYS.USER, id],
    queryFn: () =>
      fetchAPI({
        method: "GET",
        url: `${SERVICE_URL.USER.USER_API}/${id}`,
        queryParams: {},
      }),
    enabled: !!id,
  });
};

// ADD USER
export const useAddUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) =>
      fetchAPI({
        method: "POST",
        url: SERVICE_URL.USER.USER_API,
        queryParams: {},
        data: data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.USER]);
    },
  });
};

// UPDATE USER
export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...data }) =>
      fetchAPI({
        method: "PUT",
        url: `${SERVICE_URL.USER.USER_API}/${id}`,
        queryParams: {},
        data: data,
      }),
    onSuccess: () => {
      // queryClient.invalidateQueries([QUERY_KEYS.USER]);
    },
  });
};

// DELETE USER
export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) =>
      fetchAPI({
        method: "DELETE",
        url: `${SERVICE_URL.USER.USER_API}/${id}`,
        queryParams: {},
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.USER]);
    },
  });
};

// RESEND VERIFICATION
export const useResendVerification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) =>
      fetchAPI({
        method: "POST",
        url: SERVICE_URL.USER.USER_RESEND_VERIFICATION_API,
        queryParams: {},
        data: data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.USER]);
    },
  });
};
